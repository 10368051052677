import {BasicsClass} from "@/common/BasicsClass";

export default class GoodsClassAPIClass extends BasicsClass {
    public apiUrls: any = new Map([
        ['getList', {
            url: '/category/lists',
            name: 'list',
            label: '列表'
        }],
        ['add', {
            url: '/category/add',
            name: 'add',
            label: '添加'
        }],
        ['save', {
            url: '/category/save',
            name: 'save',
            label: '保存'
        }],
        ['delete', {
            url: '/category/delete',
            name: 'delete',
            label: '删除'
        }],
    ])


    //商品分类列表
    public categorylists(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('getList').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }
    public categoryadd(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('add').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }
    public categorysave(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('save').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public categorydelete(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('delete').url
        this.BasicPost(
            url,
            {
                ...params,
                // startDate,
                // endDate,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }
}
